import React, { FC, useEffect, useState } from 'react';
import { Answer } from '../../../utils/models';
import moment from 'moment';
import { AnalyticsBarChart } from '../../../common/components/bar-chart';
import RecomendationCard from './recomendation-card';
import { getRecomendations } from '../../../services/recomendation.service';
import { Collapsible } from '../../../common/components/collapsible';
import ReactToPrint from "react-to-print";

interface EvaluationCardProps extends Answer {
  id:string
}

const EvaluationCard: FC<EvaluationCardProps> = ({
  scores,
  completedAt,
  id
}) => {
  const [recomendations, setRecomendations] = useState<Array<any> | undefined>([]);
  const [isOpen, setIsOpen] = useState(false)
  let componentRef: any = null;

  async function loadRecomendations (scores: any) {
    const values:any = []
    Object.keys(scores).forEach(key => {
      if (!['DX_', 'RS_', 'PS_', 'GLOBAL', 'IN'].some(v => key.includes(v)) && key.includes('SCORE')){
        values.push({
          name: key,
          value: scores[key],
        });
      }   
    })
    const res = await getRecomendations(values)

    const sortedData = res?.sort((a, b) => {
      const idA = options[a.name] ? options[a.name].id : Infinity; 
      const idB = options[b.name] ? options[b.name].id : Infinity;
      return idA - idB;
    });
    setRecomendations(sortedData);
  }

  useEffect(() => {
    loadRecomendations(scores);
  }, [scores]);

  const options:any = {
    GLOBAL_SCORE: {
      id: '0',
      display: "Total"
    },
    PAE_SCORE: {
      id: '1',
      display: "Implementación de programas de alimentación escolar"
    },
    EAN_SCORE: {
      id: '2',
      display: "Implementación de la Educación Alimentaria Nutricional"
    },
    VI_SCORE: {
      id: '3',
      display: "Vigilancia de alimentos con exceso o altos en grasa saturada, azúcar y sal"
    },
    CR_SCORE: {
      id: '4',
      display: "Coordinación con instituciones"
    },
    FN_SCORE: {
      id: '5',
      display: "Gestión de recursos financieros"
    },
    LAS_SCORE: {
      id: '6',
      display: "Espacios y aplicación de normativas para cuidar la salud nutricional de niños y niñas"
    }
  }

  const reactToPrintTrigger = () => {
    return <button className="primary-button" style={{height: '42px'}}>Imprimir</button>;
  };

  const setComponentRef = (ref:any) => {
    componentRef = ref;
    console.log(ref)
  };

  const reactToPrintContent = () => {
    return componentRef;
  };

  return (
    <Collapsible setIsOpen={setIsOpen} isOpen={isOpen} initialHeight={48}>
    <div className="school-card">
      <div className='detail-evaluation' style={{display: 'flex', justifyContent:'space-between', background: '#6D31AC', position:'absolute', width: '100%', top: 0, left: 0, padding: '14px 16px 2px 16px', color: 'white'}}>
        <p>Evaluación {moment(completedAt).format('DD/MM/YY')}</p>
        {isOpen ? <p>Ocultar</p> : <p>Mostrar detalle</p>}
      </div>
      <br /> 
      <br />
      <div style={{backgroundColor: 'white', borderRadius: '16px', border: '1px solid #DFE2E3'}}>
      <div className='container-chart'>
        <div className='container-chart-data'>
          <table>
          <tbody>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 14m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                  <path d="M12 11v-6a2 2 0 0 1 2 -2h2v1a2 2 0 0 1 -2 2h-2"></path>
                  <path d="M10 10.5c1.333 .667 2.667 .667 4 0"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.PAE_SCORE.display}`}</p></td>
            </tr>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0"></path>
                  <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0"></path>
                  <path d="M3 6l0 13"></path>
                  <path d="M12 6l0 13"></path>
                  <path d="M21 6l0 13"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.EAN_SCORE.display}`}</p></td>
            </tr>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                  <path d="M14.473 17.659a8.897 8.897 0 0 1 -2.473 .341c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
                  <path d="M19 16v3"></path>
                  <path d="M19 22v.01"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.VI_SCORE.display}`}</p></td>
            </tr>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M3 21v-13l9 -4l9 4v13"></path>
                  <path d="M13 13h4v8h-10v-6h6"></path>
                  <path d="M13 21v-9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v3"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.CR_SCORE.display}`}</p></td>
            </tr>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M16 6m-5 0a5 3 0 1 0 10 0a5 3 0 1 0 -10 0"></path>
                  <path d="M11 6v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
                  <path d="M11 10v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
                  <path d="M11 14v4c0 1.657 2.239 3 5 3s5 -1.343 5 -3v-4"></path>
                  <path d="M7 9h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5"></path>
                  <path d="M5 15v1m0 -8v1"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.FN_SCORE.display}`}</p></td>
            </tr>
            <tr>
              <td>
                <svg style={{marginRight: '6px'}} width={24} height={24} viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                  <path d="M5 8v-3a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5"></path>
                  <path d="M6 14m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                  <path d="M4.5 17l-1.5 5l3 -1.5l3 1.5l-1.5 -5"></path>
                </svg>
              </td>
              <td><p className='table-text'>{ `${options.LAS_SCORE.display}`}</p></td>
            </tr>
          </tbody>
        </table>
        </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}>
            <p style={{textAlign: 'center', fontSize: '16px', lineHeight: 1.5, marginBottom: '8px', fontWeight: 700, color: '#6D31AC'}}>{'NIVEL DE ACCIONES O ELEMENTOS QUE FOMENTAN AMBIENTES ALIMENTARIOS ESCOLARES SALUDABLES'}</p>
            {(scores &&
              <AnalyticsBarChart
                value={scores.GLOBAL_SCORE || '-'}
                segments={scores}
                displayOptions={options}
              />
            )}
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', padding: '0 32px' }}>
          <div style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
            <div className='container-legend'>
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  backgroundColor: '#56B448',
                  marginRight: '8px',
                  flexShrink: 0,
                }}
              ></div>
              <span style={{ color:'#56B448', fontWeight: 'bold', fontSize:'12px' }}>Satisfactorio</span>
            </div>
            <div className='container-legend-2'>
              <p style={{ color: 'black', margin: 0, fontSize:'12px', textAlign:'justify' }}>Existen acciones que están fomentando un ambiente alimentario escolar saludable. Es recomendable compartir con otros acerca de qué y cómo lo han hecho para ayudar a sostener estas acciones en comunidad.</p>
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
            <div className='container-legend'>
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  backgroundColor: '#FFD700',
                  marginRight: '8px',
                  flexShrink: 0,
                }}
              ></div>
              <span style={{ color:'#FFD700', fontWeight: 'bold', fontSize:'12px' }}>Medianamente satisfactorio</span>
            </div>
            <div className='container-legend-2'>
              <p style={{ color: 'black', margin: 0, fontSize:'12px', textAlign:'justify' }}>Se están fomentando acciones para un ambiente alimentario escolar saludable; sin embargo, aún existen áreas para seguir trabajando. Se requiere revisar, en comunidad, la manera más óptima para mejorar.</p>
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
            <div className='container-legend'>
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  backgroundColor: '#FC9416',
                  marginRight: '8px',
                  flexShrink: 0,
                }}
              ></div>
              <span style={{ color:'#FC9416', fontWeight: 'bold', fontSize:'12px' }}>Poco satisfactorio</span>
            </div>
            <div className='container-legend-2'>
              <p style={{ color: 'black', margin: 0, fontSize:'12px', textAlign:'justify' }}>Existen algunas acciones con las que se está fomentando un ambiente alimentario escolar saludable, pero aún no son suficientes.  Se requiere revisar, en comunidad, la manera más óptima para mejorar.</p>
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%', marginBottom: '10px' }}>
            <div className='container-legend'>
              <div
                style={{
                  width: '12px',
                  height: '12px',
                  backgroundColor: '#E33945',
                  marginRight: '8px',
                  flexShrink: 0,
                }}
              ></div>
              <span style={{ color:'#E33945', fontWeight: 'bold', fontSize:'12px' }}>Nada satisfactorio</span>
            </div>
            <div className='container-legend-2'>
              <p style={{ color: 'black', margin: 0, fontSize:'12px', textAlign:'justify' }}>Existen muy pocas o nulas acciones que fomentan un ambiente alimentario escolar saludable, se requiere revisar en comunidad, la manera más óptima para iniciar dichas acciones.</p>
            </div>
          </div>
        </div>
      </div>
      {recomendations 
      ? <div style={{display:'flex', justifyContent:'space-between', padding:'12px', alignItems: 'center'}}>
          <h4 style={{marginBottom:'12px', fontSize: '16px'}}>Recomendaciones de Nutrento</h4>
          <ReactToPrint 
            content={reactToPrintContent}
            documentTitle="Recomendaciones"
            removeAfterPrint
            trigger={reactToPrintTrigger}
            pageStyle="print-recomendations"
          />
        </div> 
      : null }
      <div>
      <div id={id} ref={setComponentRef} >
        {
          recomendations && recomendations?.map(r => (
            <RecomendationCard key={r._id} {...r} />
          ))
        }
      </div>
      </div>
    </div>
    </Collapsible>
  );
}

export default EvaluationCard;